import React, { useEffect, useState } from "react";

import CodewarsChallengesCollection from "../../../components/codewarsChallengesCollection/CodewarsChallengesCollection";
import CodilityChallengesCollection from "../../../components/codilityChallengesCollection/CodilityChallengesCollection";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import "./ProblemSets.scss";
import ShadowBox from "../../../layoutComponents/shadowBox/ShadowBox";
import { MenuItem } from "@material-ui/core";
import useSessionStorage from "../../../hooks/useSessionStorage";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ProblemSets({ playerInfo, isProfile }) {

  const { id, username, language, leetCodeUsername } = playerInfo;
  const [sessionStorage, setSessionStorage] = useSessionStorage("language", "javascript");
  const [selectedLanguage, setSelectedLanguage] = useState(sessionStorage);
  const [completedProblems, setCompletedProblems] = useState([]);
  const [problemsBySets, setProblemsBySets] = useState([]);
  const [problems, setProblems] = useState([]);

  let tabValue = 0;
  const [value, setValue] = useState(tabValue);

  const fetchProblems = async () => {
    const response = await fetch(`${process.env.REACT_APP_API}/problems`);
    const data = await response.json();
    convertAndFilterProblems(data);
    setProblems(data);
  };

  useEffect(() => {
    fetchProblems();
  }, []);

  useEffect(() => {
    async function fetchAllCompletedProblems() {
      try {
        // List all the sources from which you want to fetch
        const sources = [{
          name: "codewars", 
          username: username
        },{
          name: "leetcode",
          username: leetCodeUsername
        }];

        // Trigger all fetches in parallel using Promise.all
        const results = await Promise.all(
          sources.map(async (source) => {
            if(!source.username) return [];
            const res = await fetch(`${process.env.REACT_APP_API}/${source.name}/completed/${source.username}`);
            return res.json();
          })
        );
        
        // Combine or “flatten” all arrays of completed problems
        const allCompletedProblems = results.flat();
  
        // Update state just once with the combined list
        setCompletedProblems(allCompletedProblems);
      } catch (error) {
        console.error("Error fetching completed problems:", error);
      }
    }
  
    fetchAllCompletedProblems();
  }, [username]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sections = [
    {
      tabName: "Intro",
      source: "codewars",
      sets: ["grasshopper", "grasshopper-debug", "strings1"],
    },
    {
      tabName: "Beginner",
      source: "codewars",
      sets: ["strings2", "arraysIntro", "hashTables", "controlFlow", "mathIntro", "OOP"],
    },
    {
      tabName: "Intermediate",
      source: "codewars",
      sets: ["strings3", "strings4", "arrays", "objects", "higherOrderFunctions", "matrixes"],
    },
    {
      tabName: "Algorithms",
      source: "codewars",
      sets: ["recursion", "cryptography", "bubbleSort"],
    },
    {
      tabName: "Data Structures",
      source: "codewars",
      sets: ["linkedLists", "queues", "trees"],
    },
    {
      tabName: "Codility",
      source: "codility",
      instructions:
        "For Codility problems, please submit the url to your submitted solution next to the completed problem.",
      sets: ["General Problems", "Stacks"],
    },
    {
      tabName: "Leetcode",
      source: "leetcode",
      sets: ["Top Interview Questions Easy: Arrays", "Top Interview Questions Easy: Strings"],
    },
  ];

  const convertAndFilterProblems = (problems) => {
    const filteredProblems = problems.reduce((acc, problem) => {
      if (problem.source !== "codewars" || problem?.languages?.includes(selectedLanguage)) {
        if (!acc[problem.problem_set]) {
          acc[problem.problem_set] = [];
        }
        acc[problem.problem_set].push(problem);
      }

      return acc;
    }, {});

    setProblemsBySets(filteredProblems);
  };

  useEffect(async () => {
    convertAndFilterProblems(problems);
  }, [selectedLanguage]);

  const handleSelectLanguage = (e) => {
    const language = e.target.value;
    setSelectedLanguage(language);
    setSessionStorage(language);
  };

  const tabs = [];
  const tabPanelsWithProblems = [];

  sections.forEach((section, index) => {
    tabs.push(<Tab label={section.tabName} {...a11yProps(index)} />);

    tabPanelsWithProblems.push(
      <TabPanel value={value} index={index}>
        {section.instructions && <p>{section.instructions}</p>}

        {section.sets.map((set) => {
          if (section.source == "codewars" || section.source == "leetcode") {
            return (
              <CodewarsChallengesCollection
                language={selectedLanguage}
                completedProblems={completedProblems}
                problemSet={set}
                selectedProblemSet={problemsBySets[set]}
              />
            );
          } else if (section.source == "codility") {
            return (
              <CodilityChallengesCollection userId={id} problemSet={set} selectedProblemSet={problemsBySets[set]} />
            );
          }
        })}
      </TabPanel>
    );
  });

  const languageSelector = (
    <FormControl className="languageSelector" style={{ width: "200px" }}>
      <InputLabel id="language-selector">Language</InputLabel>
      <Select
        fullWidth
        labelId="language-selector"
        id="language-selector"
        value={selectedLanguage}
        label="Language"
        onChange={handleSelectLanguage}
      >
        <MenuItem value="javascript">JavaScript</MenuItem>
        <MenuItem value="java">Java</MenuItem>
        <MenuItem value="python">Python</MenuItem>
      </Select>
    </FormControl>
  );

  return (
    <ShadowBox
      className="codeChallenges"
      title="Code Challenges"
      cta={isProfile ? languageSelector : <div></div>}
      displayDescription="flex"
    >
      <Tabs value={value} className="sideTabs" onChange={handleChange} orientation="vertical">
        {tabs}
      </Tabs>

      <div className="challengeLists">{tabPanelsWithProblems}</div>
    </ShadowBox>
  );
}

export default ProblemSets;
