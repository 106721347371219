import React, { useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';

import Collapsible from 'react-collapsible';

import './CodewarsChallengesCollection.scss';

const CodewarsChallenge = ({ name, url, rank, videoUrl, completed }) => {
  const challengeLevel = Math.abs(rank) || "";
  
  return (
    <div className="codewarsChallenge">
      <a href={url} target="_blank" rel="noreferrer">
        <Checkbox
          color="primary"
          checked={!!completed}
          disabled={!completed}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
        <span className="codewarsChallenge__title">{name}</span>
        <span className="codewarsChallenge__rank"> {challengeLevel}</span>
      </a>
      {videoUrl && (
        <a
          className="codewarsChallenge__videoUrl"
          href={videoUrl}
          target="_blank"
          rel="noreferrer"
          style={{ float: "right" }}
        >
          <Button variant="contained" color="primary">
            Video Lesson
          </Button>
        </a>
      )}
    </div>
  );
};

const ProblemSetHeader = ({ title, completedCount, problemCount }) => {
  completedCount = completedCount || 0;
  return (
    <div className="problemSetHeader">
      <span className="problemSetHeader__title">{title}</span>
      <span className="problemSetHeader__count">
        Complete: {completedCount}/{problemCount}
      </span>
    </div>
  );
};

const CodewarsChallengesCollection = ({
  completedProblems = [],
  language = "",
  problemSet = "Starting Problems",
  selectedProblemSet = [],
}) => {
  // Determine if a challenge is completed
  const isChallengeCompleted = (challenge) => {
    return completedProblems.some((p) => (p.slug || p.title_slug) === challenge.slug);
  };

  const problemElements = selectedProblemSet.map((challenge, index) => {
    const completed = isChallengeCompleted(challenge);
    return (
      <CodewarsChallenge
        key={index}
        name={challenge.title}
        rank={challenge.difficulty}
        url={`${challenge.url}/${language}`}
        videoUrl={challenge.videoUrl}
        completed={completed}
      />
    );
  });

  // Count how many challenges are marked completed
  const completedCount = problemElements.filter((el) => el.props.completed).length;
  return (
    <Collapsible
      trigger={
        <ProblemSetHeader title={problemSet} problemCount={selectedProblemSet.length} completedCount={completedCount} />
      }
      classParentString="challengeCollection"
      open={completedCount !== selectedProblemSet.length}
    >
      <div className="collectionOfCodewarsChallenges__container">{problemElements}</div>
    </Collapsible>
  );
};

export default CodewarsChallengesCollection;