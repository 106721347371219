import React, {useState, useEffect, useRef} from 'react';
import { useDebouncedCallback } from "use-debounce";

//Services
import {fetchUsersOfOrganizationWithPagination} from '../../services/usersService';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import Pagination from "@mui/material/Pagination";
import TextField from '@material-ui/core/TextField';
import { visuallyHidden } from '@mui/utils';

//COMPONENTS
import MemberListItem from '../memberListItem/MemberListItem';
import Modal from "../../utilComponents/modal/Modal";
import { scrollToElement } from '../../utilComponents/scroll/scrollToElement.tsx';
import EmptyView from "../emptyView/EmptyView";

//STYLES
import './MemberList.scss';

const emptyViewStyles = {
    justifyContent: "center", 
    fontSize: "2em",
};

function MemberList({ role="Student", subdomain, jwtToken, setToastMessage}) {

    const [members, setMembers] = useState([]);
    const [totalMembers, setTotalMembers] = useState(0);
    const [checked, setChecked] = useState([]);
    const [studentSearchTerm, setStudentSearchTerm] = useState('');
    const [staffSearchTerm, setStaffSearchTerm] = useState('');
    const [showRemoveMemberModal, setShowRemoveMemberModal] = useState(false);
    const [memberToRemove, setMemberToRemove] = useState(null);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(null);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = useState(true);
    const positionRef = useRef(null);

    const debounced = useDebouncedCallback((newSearchTerm) => {
        filterUsersBySearchTerm(newSearchTerm);
    }, 300);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    let organization = subdomain || 'codetrack';

    useEffect(() => {
        // Fetch data based on the role
        if (role === "Staff") {
            refreshStaff(subdomain);
        }

        if (role === "Student" && !studentSearchTerm) {
            refreshStudents(page);
        }

    }, [page]);

    const refreshStudents = async (page) => {
        try {
            // Fetch both employed and unemployed in parallel
            const response = await fetchUsersOfOrganizationWithPagination(
                organization,
                page,
                [], // cohort parameter
                true // employed
            );

            if (response.status === "success") {
                setMembers(response.users);
                setTotalMembers(response.count);
            }

        } catch (error) {
            console.error("Error fetching users:", error);
        }
        finally {
            setLoading(false); 
        }
    }

    const refreshStaff = async () => {
        let url = `${process.env.REACT_APP_API}/users?role=staff&organization=${organization}`;

        const requestOptions = {
            headers: { 'Authorization': `Bearer ${jwtToken}` },
        };

        const staffRes = await fetch(url, requestOptions);
        const staffData = await staffRes.json();


        setMembers(staffData.users);
        setTotalMembers(staffData.users.length);
        setLoading(false)
    }

    const filterUsersBySearchTerm = async (searchTerm) => {
        const result = await fetchUsersOfOrganizationWithPagination(
            subdomain,
            1,
            [],
            true,
            studentSearchTerm
        )

        setMembers(result.users)
        setTotalMembers(result.count)
        setLoading(false)

    };

    const handleSearchChange = (e) => {
        const newSearchTerm = e.target.value.toLowerCase();
        setStudentSearchTerm(newSearchTerm);
        debounced(newSearchTerm);
        scrollToElement(positionRef, 50);
    };


    const handleRemoveMemberModal = (id) => {
        setMemberToRemove(id);
        setShowRemoveMemberModal(true);
    }

    const removeMember = () => {

        const url = `${process.env.REACT_APP_API}/accountManagement/${organization}/removemember/${memberToRemove}`

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwtToken}`
            },
        };

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    if (role === "Student") {
                        refreshStudents();
                    } else {
                        refreshStaff();
                    } 
                    setToastMessage("Member removed");
                } else {
                    setToastMessage("There was an error removing member");
                }
            }).catch((error)=>{
                console.error(error);
                setToastMessage("There was an error removing member");
            })
        setShowRemoveMemberModal(false);
        setMemberToRemove(null);
    }

    const handleBadgesSyncing= async(user_uuid)=> {
        let url = `${process.env.REACT_APP_API}/badges/sync/${user_uuid}`;
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwtToken}`
            },
        };
        try{
            const response = await fetch(url, requestOptions);
            const data = await response.json();
            if(!response.ok){
                throw new Error(data);
            } else {
                setToastMessage("Badges synced successfully");
            }
            await new Promise(resolve => setTimeout(resolve, 300));
        } catch(error){
            setToastMessage("There was an error syncing the badges");
            console.error(error);
        }
    }

    const handleChangeEmploymentStatus = async (id, currentStatus) => {
        const url = `${process.env.REACT_APP_API}/users/${id}/employed`;

        try {
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify({ employed: !currentStatus })
            });

            if (!response.ok) {
                throw await response.json();
            }

            setMembers(prevMembers =>
                prevMembers.map(member =>
                    member.uuid === id
                        ? { ...member, employed: !currentStatus }
                        : member
                )
            );

            setToastMessage("Employment status updated successfully");
        } catch (error) {
            console.error(error);
            setToastMessage("There was an error changing the employment status");
        }
    };

    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'role',
            numeric: false,
            disablePadding: false,
            label: 'Role'
        },
        {
            id: 'cohort',
            numeric: false,
            disablePadding: false,
            label: 'Cohort',
        },
        {
            id: 'employed',
            numeric: false,
            disablePadding: false,
            label: 'Employed',
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Actions',
            disableSort: true
        }
    ];

    if (orderBy) {
        members.sort(getComparator(order, orderBy));
    }

    return (
        <div className="memberList" data-testid="memberList-container">
            <Modal showModal={showRemoveMemberModal} setShowModal={setShowRemoveMemberModal}>
                Are you sure you want to remove this member?
                <button data-testid="remove-button" onClick={removeMember}>Yes</button>
                <button onClick={()=>setShowRemoveMemberModal(false)}>No</button>
            </Modal>

            <div className="memberList__sorting">
                <h3 data-testid="memberList-title"ref={positionRef}> {role} List</h3>
                <div className="memberList__count"><span>{role} count:</span>{totalMembers}</div>
                <TextField
                    id="search-user"
                    label="Search"
                    variant="outlined"
                    value={role === "Student" ? studentSearchTerm : staffSearchTerm}
                    onChange={(e) => role === "Student" && handleSearchChange(e)}
                    
                />
            </div>

            {!loading && members.length < 1 ? (
            <EmptyView styles={emptyViewStyles} text={"No Results"} />
        ) : (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => {
                                return headCell.disableSort ? (
                                    <TableCell align="center" key={headCell.id}>{headCell.label}</TableCell>
                                ) : (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.numeric ? 'right' : 'center'}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody data-testid="member-ListTable">
                        {members.map((member) => {
                            return (
                                <MemberListItem
                                    key={member.uuid}
                                    member={member}
                                    handleChangeEmploymentStatus={() => handleChangeEmploymentStatus(member.uuid, member.employed)}
                                    handleBadgesSyncing={() => handleBadgesSyncing(member.uuid)}
                                    handleRemoveMemberModal={() => handleRemoveMemberModal(member.uuid)}
                                    checked={checked}
                                    setChecked={setChecked}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )}
            <Pagination
                count={Math.ceil(totalMembers / 50)}
                page={page}
                color="primary"
                size="large"
                sx={{
                    bgcolor: "inherit",
                    display: "flex",
                    justifyContent: "center",
                    minWidth: "375px",
                }}
                onChange={(_, newPage) => {
                    setPage(newPage);
                    scrollToElement(positionRef, 50);
                }}
            />
        </div>
    );
}

export default MemberList;



