import { useState, ChangeEvent, FormEvent } from 'react'
import { useHistory } from "react-router-dom";

import { FormData } from '../types'

import { resetPassword } from '../services/resetPasswordService'


export const useResetPassword = () => {
  const [formData, setFormData] = useState<FormData>({
    email: '',
    securityCode: '',
    newPassword: '',
    confirmNewPassword: '',
  })

  const [errors, setErrors] = useState<Partial<FormData>>({})

  const history = useHistory()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const validateForm = (): boolean => {
    const newErrors: Partial<FormData> = {}

    if (!formData.email) {
      newErrors.email = 'Email is required'
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid'
    }

    if (!formData.securityCode) {
      newErrors.securityCode = 'Security code is required'
    }

    if (!formData.newPassword) {
      newErrors.newPassword = 'New password is required'
    } else if (formData.newPassword.length < 8) {
      newErrors.newPassword = 'Password must be at least 8 characters long'
    }

    if (!formData.confirmNewPassword) {
      newErrors.confirmNewPassword = 'Confirm new password is required'
    } else if (formData.newPassword !== formData.confirmNewPassword) {
      newErrors.confirmNewPassword = 'Passwords do not match'
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (validateForm()) {
        await resetPassword(formData);
        history.push({
            pathname: '/login',
            state: { toastMessage: "Your password has been changed! Please log in." }
        })

    } else {
      console.log('Form is invalid. Please correct the errors.')
    }
  }

  return { formData, handleChange, handleSubmit, errors }
}