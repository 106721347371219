import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import ShadowBox from "../../layoutComponents/shadowBox/ShadowBox";
import ProblemSet from "../../components/problemSet/ProblemSet";
import AddProblem from "./AddProblem";

import "./Problems.scss";

function Problems(props) {
  const history = useHistory();

  const [problemSets, setProblemSets] = useState([]);

  const handleRefresh = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API}/problems`);
      const problems = await res.json();
      const filteredProblems = problems.reduce((acc, problem) => {
        if (!acc[problem.problem_set]) {
          acc[problem.problem_set] = [];
        }
        acc[problem.problem_set].push(problem);

        return acc;
      }, {});

      setProblemSets(filteredProblems);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleRefresh();
    history.push("/problems");
  }, []);

  return (
    <div className="problems">
      <div className="container">
        <div className="problems__addNewProblem">
          <AddProblem handleRefresh={handleRefresh} />
        </div>
        <h2>Problems</h2>
        <ShadowBox title="CodeTrack Problem Sets">
          {Object.keys(problemSets).sort().map((problemSetId, index) => (
            <ProblemSet
              index={index}
              key={problemSetId}
              title={problemSetId}
              problems={problemSets[problemSetId]}
              handleRefresh={handleRefresh}
            />
          ))}
        </ShadowBox>
      </div>
    </div>
  );
}

export default Problems;
