// hooks/useToast.ts
import { useState } from 'react';

interface ToastHook {
  showToast: boolean;
  toastMessage: string;
  displayToast: (message: string, duration?: number) => void;
  hideToast: () => void;
}

/**
 * Custom hook for managing toast notifications
 * @returns Toast state and control functions
 */
export const useToast = (): ToastHook => {
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');

  /**
   * Display a toast notification with the specified message
   * @param message - The message to display in the toast
   * @param duration - Optional duration in milliseconds (default: 2000)
   */
  const displayToast = (message: string, duration: number = 2000): void => {
    setToastMessage(message);
    setShowToast(true);

    // Auto-hide the toast after the specified duration
    if (duration > 0) {
      setTimeout(() => {
        setShowToast(false);
      }, duration);
    }
  };

  /**
   * Hide the current toast notification
   */
  const hideToast = (): void => {
    setShowToast(false);
  };

  return {
    showToast,
    toastMessage,
    displayToast,
    hideToast,
  };
};