import React, {useState, useEffect} from 'react';

import Button from '@mui/material/Button';

import './PlanSummary.scss';

type PlanSummaryProps = {
    orgConfig: {
        id: string;
    }
}

function PlanSummary({orgConfig}: PlanSummaryProps) {

    const {id} = orgConfig;
    const [accountInfo, setAccountInfo] = useState<any>({});
   
    useEffect(() => {
        fetchAccountInfo();
    }, [])

    const fetchAccountInfo = () => {
        const url = `${process.env.REACT_APP_API}/organizations/${id}`

        fetch(url)
        .then(response => response.json())
        .then(data => {
            setAccountInfo(data);
        })
    }

    return (
        <div>
            <h3>Plan Summary: {accountInfo.org_name}</h3>
            <div className="planSummary__details">
                <div><span className="bold">Plan:</span> Silver</div>
                <div><span className="bold">Member count:</span>{accountInfo.current_member_count}/{accountInfo.max_members}</div>
                <Button  variant="contained" onClick={() =>console.log('go to account') }>Manage plan</Button>
            </div>
           
        </div>
    );
}

export default PlanSummary;