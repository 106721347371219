import { useState, useEffect, useCallback } from 'react';

// types.ts
export interface WebhookNotification {
  type: 'WEBHOOK_CONNECTED' | 'WEBHOOK_ERROR';
  message: string;
  timestamp: string;
  data: {
    webhookId?: string;
    error?: string;
  };
}

interface UseWebSocketProps {
  userUUID?: string;
  autoConnect?: boolean;
  onNotification?: (notification: WebhookNotification) => void;
}

interface UseWebSocketReturn {
  isConnected: boolean;
  webhookIsConnected: boolean;
  connect: (uuid?: string) => void;
  disconnect: () => void;
}

export const useWebSocket = ({ 
  userUUID,
  autoConnect = false,
  onNotification 
}: UseWebSocketProps): UseWebSocketReturn => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [webhookIsConnected, setWebhookIsConnected] = useState<boolean>(false);
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [currentUUID, setCurrentUUID] = useState<string | undefined>(userUUID);

  const disconnect = useCallback(() => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.close();
      setWs(null);
    }
  }, [ws]);

  const connect = useCallback((uuid?: string) => {
    // Disconnect existing connection if any
    disconnect();
    
    // Update the UUID if provided
    const connectingUUID = uuid || currentUUID;
    if (!connectingUUID) {
      console.error('Cannot connect: No UUID provided');
      return;
    }
    
    setCurrentUUID(connectingUUID);
    
    const newWs = new WebSocket(`wss://codewars-tracker-be.herokuapp.com/ws?uuid=${connectingUUID}`);

    newWs.onopen = () => {
      setIsConnected(true);
      console.log('connecting')
    };

    newWs.onmessage = (event: MessageEvent) => {
      try {
        const notification: WebhookNotification = JSON.parse(event.data);
        
        console.log(notification);

        if (notification.type === 'WEBHOOK_CONNECTED') {
            console.log('WEBHOOK CONNECTED')
          setWebhookIsConnected(true);
        }

        onNotification?.(notification);
      } catch (error) {
        console.error('Failed to parse WebSocket message:', error);
      }
    };

    newWs.onclose = () => {
      setIsConnected(false);
      setWs(null);
      
      // Only attempt reconnection if we still have a UUID
    //   if (currentUUID) {
    //     setTimeout(() => connect(currentUUID), 3000);
    //   }
    };

    newWs.onerror = (error: Event) => {
      console.error('WebSocket error:', error);
      setIsConnected(false);
      setWebhookIsConnected(false);
    };

    setWs(newWs);
  }, [currentUUID, disconnect, onNotification]);

  // Handle cleanup only on component unmount
  useEffect(() => {
    return () => {
      // This will only run when the component using the hook unmounts
      disconnect();
    };
  }, []); // Empty dependency array means this only runs on mount/unmount

  return {
    isConnected,
    webhookIsConnected,
    connect,
    disconnect
  };
};