import React, { useEffect, useState } from "react";
import ShadowBox from "../../layoutComponents/shadowBox/ShadowBox";
import { useFetchLeetcode } from "../../hooks/useFetchLeetcode";

import "./PointsBreakdown.scss";
function PointsBreakdown({ playerInfo }) {

  const { uuid, leetcodeid, honor, total_points } = playerInfo;
  const { leetcodeData, loading, error } = useFetchLeetcode(uuid, leetcodeid);
  const [battingCagePoints, setBattingCagePoints] = useState(null);
    
  const handleGetBattingCagePoints = async () => {
    const accessToken = localStorage.getItem("accessToken");
    
    const res = await fetch(process.env.REACT_APP_API + "/battingCagePoints/" + uuid, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await res.json();
    // Handle if user never earned any batting cage points
    const totalPoints = data?.total_points || 0;
    setBattingCagePoints(totalPoints);
  };

  useEffect(() => {
    if(playerInfo.uuid){
      handleGetBattingCagePoints();
    }
  }, [playerInfo]);

  return (
    <ShadowBox className="points-breakdown" title="Point Breakdown" loading={loading}>
      {!loading && (
        <>
          <div className="points-breakdown__source">
            <div className="points-breakdown__label">Codewars:</div>
            <div>{honor}</div>
          </div>

          <div className="points-breakdown__source">
            <div className="points-breakdown__label">Leetcode:</div>
            <div>{leetcodeData.total}</div>
          </div>
          <div className="points-breakdown__leetcode-problems">
            <table>
              <tbody>
                {Object.keys(leetcodeData.problems).map((key) => {
                  const formattedKey = key[0].toUpperCase() + key.slice(1);
                  return (
                    <tr key={formattedKey}>
                      <td>{formattedKey}:</td>
                      <td>{leetcodeData.problems[key]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="points-breakdown__source">
            <div className="points-breakdown__label">Batting Cage:</div>
            <div>{battingCagePoints}</div>
          </div>

          <div className="points-breakdown__source">
            <div className="points-breakdown__label">Total:</div>
            <div>{total_points}</div>
          </div>
        </>
      )}
    </ShadowBox>
  );
}

export default PointsBreakdown;
