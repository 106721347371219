import React from "react";

import PointsBreakdown from "../../components/pointsBreakdown/PointsBreakdown";
import BadgeList from "../../components/badgeList/BadgeList";
import BarGraph from "../../components/barGraph/BarGraph";
import ShadowBox from "../../layoutComponents/shadowBox/ShadowBox";

import "./StudentDetailSubviews.scss";

function Dashboard({ playerInfo }) {
  return (
    <>
      <div className='profileInfo'>
        <PointsBreakdown playerInfo={playerInfo} />
        {playerInfo?.uuid && <BadgeList userId={playerInfo.uuid} />}
      </div>
      <ShadowBox className='dashboard__barChart'>
        {playerInfo.uuid && <BarGraph userid={playerInfo.uuid} weeks={16} />}
      </ShadowBox>
    </>
  );
}

export default Dashboard;
