import { useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { SelectChangeEvent } from "@mui/material/Select";

import { createAccount } from "../services/acctService";

import { CreateAccountForm } from "../types";
import useSessionStorage from "../../../hooks/useSessionStorage";

// @ts-ignore
import postBattingCageState from "./../../../pages/battingCage/src/utils/postBattingCagePoints";

export interface CreateAccountLocationState {
  redirectRoute?: string;
}

const useCreateAcct = (subdomain: string, setLoggedIn: (loggedIn: boolean) => void) => {
  const [storedValue, , removeSessionKey] = useSessionStorage<{ [key: string]: any }>("tempBattingCageState", {});

  const [formData, setFormData] = useState<CreateAccountForm>({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    cohort: "",
    organization: subdomain,
  });

  const [errors, setErrors] = useState<Partial<CreateAccountForm> & { general?: string }>({});

  const history = useHistory();
  const location = useLocation<CreateAccountLocationState>();
  const handleChange = useCallback(
    (e: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    },
    []
  );

  const validateForm = useCallback(() => {
    const newErrors: Partial<CreateAccountForm> = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  async function postAllBattingCageSessionData(accessToken: any) {
    const levels = ["intro", "easy", "medium", "hard"];

    for (const level of levels) {
      if (storedValue[level] !== undefined) {
        await postBattingCageState(accessToken, level, storedValue[level]);
      }
    }
  }

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (validateForm()) {
        try {
          const response = await createAccount(formData);
          if (response && response.success) {
            setLoggedIn(true);

            // Look for batting cage state in session storage and post to the server
            await postAllBattingCageSessionData(response.accessToken);
            removeSessionKey();
            localStorage.setItem("accessToken", response.accessToken);

            const redirectRoute = location.state?.redirectRoute || "/howDoesItWork";

            history.push({
              pathname: redirectRoute,
              state: { toastMessage: "Account successfully created!" },
            });
          } else {
            console.error("Error creating account:", response?.message);
            setErrors((prevErrors) => ({ ...prevErrors, general: response?.message }));
          }
        } catch (error) {
          console.error("Error creating account:", error);
          setErrors((prevErrors) => ({
            ...prevErrors,
            general: error instanceof Error ? error.message : "An error occurred while creating the account.",
          }));
        }
      }
    },
    [formData, validateForm, history, setLoggedIn, subdomain]
  );
  return {
    formData,
    errors,
    handleChange,
    handleSubmit,
  };
};

export default useCreateAcct;
