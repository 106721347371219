import React from "react";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";
import "./CodeChallenge.scss";
const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  ...(isDragging && {
    background: "rgb(235,235,235)",
  }),
});

const CodeChallenge = ({ problem, refreshProblems, index }) => {
  const { title, url, difficulty, id, order_num } = problem;

  const removeProblem = (e) => {
    const authToken = localStorage.getItem("accessToken");

    const url = `${process.env.REACT_APP_API}/problems/${id}`;
    fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => response.json())
      .then(() => {
        refreshProblems();
      })
      .catch((error) => {
        console.log("Error while deleting".error);
      });
  };

  return (
    <TableRow component={DraggableComponent(String(id), index)} key={id}>
      {/* <TableCell align="center">
                <Checkbox
                    edge="start"
                    // checked={member.employed}
                    // onChange={()=>handleChangeEmploymentStatus(member.id, member.employed)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': 1 }}
                />
            </TableCell> */}
      <TableCell align="center">{order_num}</TableCell>
      <TableCell align="center">
        <a href={url} target="_blank">
          {title}
        </a>
      </TableCell>
      <TableCell align="center">{difficulty}</TableCell>
      <TableCell align="center">
        <IconButton aria-label="delete" size="large" onClick={removeProblem}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const DraggableComponent = (id, index) => (props) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  );
};

export default CodeChallenge;
