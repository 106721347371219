import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  Snackbar,
  Stepper,
  Step,
  StepLabel,
  Tooltip,
  Typography,
  Card,
  CardContent,
  TextField,
  Link
} from '@mui/material';

import FileCopyIcon from '@mui/icons-material/FileCopy';

import { validateCodewarsUsername, updateUserHonor, getCodewarsInfo } from '../../services/codewarsService';
import { useWebSocket } from '../../hooks/useWebSocket';

import './syncCodewarsAccount.scss';
import { useFetchBadges } from '../../hooks/useFetchBadges';
import { connected } from 'process';

const SyncCodewarsAccount: React.FC<{ loggedIn: boolean }> = ({ loggedIn }) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [username, setUsername] = useState<string>('');
  const [usernameError, setUsernameError] = useState<string | null>(null);
  const [userUUID, setUserUUID] = useState<string | null>(null);
  const [secretToken, setSecretToken] = useState<string | null>(null);
  const [honor, setHonor] = useState<number>(0);
  const [hasUserInfo, setHasUserInfo] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false)

  const history = useHistory();

  const fetchUserInfo = async () => {
    try {
      const userInfo = await getCodewarsInfo();
      
      if (userInfo.username && userInfo.webhook_id) {
        setHasUserInfo(true);
        setActiveStep(5);
      } else if (userInfo.username && !userInfo.webhook_id) {
        setUserUUID(userInfo.user_uuid);
        setSecretToken(userInfo.webhook_token);
        setActiveStep(2);
        setUsernameError(null);
      } else {
        setActiveStep(0);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  }
  useEffect(() => {
    // redirect to login if not logged in
    if (!loggedIn) {
      history.push('/login');
    }

    // fetch user info on component mount
    fetchUserInfo();

    const savedProgress = localStorage.getItem('syncCodewarsProgress');

    if (savedProgress) {
      const progress = JSON.parse(savedProgress);
      setActiveStep(progress.activeStep);
    }


  }, []);

  const {
    isConnected,
    webhookIsConnected,
    connect,
    disconnect
  } = useWebSocket({
    autoConnect: false,
    onNotification: (notification) => {
      if (notification.type === 'WEBHOOK_CONNECTED') {
        console.log('Webhook connected:', notification.message);
      }
    }
  });


  useEffect(() => {
    if (userUUID) {
      connect(userUUID);
    }
  }, [userUUID]);


  const steps: string[] = [
    'Create a Codewars Account',
    'Enter your Codewars username',
    'Navigate to Account Settings',
    'Scroll down to the section that is titled "Webhooks"',
    'Copy and paste payload url and secret into Codewars',
    'Youre good to go!',
  ];

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setShowToast(true);
  }

  const handleNext = async () => {
    if (activeStep === 1) {
      if (!username) {
        setUsernameError('Please enter a valid username.');
        return;
      } else {
        setUsernameError(null);
      }

      try {
        const codewarsTableData = await validateCodewarsUsername(username);
        setUserUUID(codewarsTableData.user_uuid);
        setHonor(codewarsTableData.honor);
        setSecretToken(codewarsTableData.webhook_token)

        localStorage.setItem('syncCodewarsProgress', JSON.stringify({
          username: username, 
          userUUID: codewarsTableData.user_uuid,
          honor:codewarsTableData.honor
        }));

        await updateUserHonor(codewarsTableData.user_uuid, codewarsTableData.username, codewarsTableData.codewars_id, codewarsTableData.honor);
        setActiveStep((prevStep) => {
          const nextStep = prevStep + 1;
          localStorage.setItem('syncCodewarsProgress', JSON.stringify({ activeStep: nextStep }));
          return nextStep;
        });
      } catch (error) {
        setUsernameError('Error validating username. Please check the username and try again.');
      }
    } else if (activeStep === 5) {
      localStorage.removeItem('syncCodewarsProgress');
      history.push('/profile');
    } else {
      setActiveStep((prevStep) => {
        const nextStep = prevStep + 1;
        localStorage.setItem('syncCodewarsProgress', JSON.stringify({ activeStep: nextStep }));
        return nextStep;
      });
    }
  };

  const renderStep1 = (): JSX.Element => (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
      <h3 style={{ "fontWeight": "600" }}>
        Create a <Link href='https://www.codewars.com/users/sign_in' target="_blank">Codewars</Link> account.
      </h3>
      <a href='https://www.codewars.com/users/sign_in' target="_blank">
        <img className='codewars-placeholder' height="300px" width='100%' src="/Codewars-Homepage.png" alt="placeholder" />
      </a>
    </Box>
  );

  const renderStep2 = (): JSX.Element => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <h3 style={{ "fontWeight": "600" }}>
        Enter your Codewars username:
      </h3>
      {usernameError && <p style={{ color: 'red' }}>{usernameError}</p>}
      <TextField
        label="Codewars Username"
        variant="outlined"
        fullWidth
        value={username}
        onChange={(e) => {
          setUsername(e.target.value);
          setUsernameError(null);
        }}
        sx={{
          mb: 2
        }}
      />
      <div><strong>Note:</strong> Your username can be found in your <a href="https://www.codewars.com/users/edit" target="_blank">account settings</a>.</div>
    </Box>
  );

  const renderStep3 = (): JSX.Element => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <h3 style={{ "fontWeight": "600" }}>
        Navigate to <a href="https://www.codewars.com/users/edit" target="_blank">Account Settings</a>
      </h3>
      <a href='https://www.codewars.com/users/edit' target="_blank">
        <img className='codewars-placeholder' height="300px" width='100%' src="/Codewars-Account-Settings.png" alt="placeholder" />
      </a>
    </Box>
  );

  const renderStep4 = (): JSX.Element => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <h3 style={{ "fontWeight": "600" }}>
        Scroll down to the section that is titled 'Webhooks'.
      </h3>
      <a href='https://www.codewars.com/users/edit' target="_blank">
        <img className='codewars-placeholder' height="300px" width='100%' src="/Codewars-Webhooks.png" alt="placeholder" />
      </a>
    </Box>
  );

  const renderStep5 = (): JSX.Element => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {!webhookIsConnected &&
        <>
          <div style={{ "fontWeight": "600" }}>Enter the Webhook URL and Secret Token</div>
          <ol style={{ padding: '0 20px' }}>
            <li>
              <p>Paste the following URL into the 'Payload url' box:</p>
              <span className="copy-url">
                https://codewars-tracker-be.herokuapp.com/codewars
                <Tooltip title="Copy">
                  <IconButton>
                    <FileCopyIcon onClick={() => copyToClipboard('https://codewars-tracker-be.herokuapp.com/codewars')} />
                  </IconButton>
                </Tooltip>
              </span>
            </li>
            <li>
              <p>Paste the token into the 'Secret' box:</p>
              <span className="copy-url">{secretToken}
                <Tooltip title="Copy">
                  <IconButton>
                    <FileCopyIcon onClick={() => copyToClipboard(`${secretToken}`)} />
                  </IconButton>
                </Tooltip>
              </span>
            </li>
            <li>
              <p>Scroll to the end of Codewars Account Settings and click Update.</p>

            </li>
          </ol>
        </>}
      {webhookIsConnected &&
        <div style={{ "textAlign": "center", "marginTop": "40px" }}>
          <img src="/check_animation.gif" alt="check animation" />
          <h3>Codewars and CodeTrack are now connected!</h3>
        </div>
      }
    </Box>
  );

  const renderStep6 = (): JSX.Element => (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h1 style={{ color: '#15154f', margin: '0px' }}>
        You're good to go!
      </h1>
      <h4 style={{ color: '#747474', textAlign: 'center', width: '73%', lineHeight: '25px', padding: '40px', margin: '0px' }}>
        Go ahead and start solving problems! Here are three good starter problems. Check out the videos if you get stuck.
      </h4>
      {/* <div className="suggested-problems"> */}
      <ul className="suggested-problems">
        <li>
          <a href="https://www.codewars.com/kata/a-needle-in-the-haystack" target="_blank"> A Needle in the Haystack</a>
          <a className="codewarsChallenge__videoUrl right" href='https://www.youtube.com/watch?v=EoXBVSvVBww' target="_blank">
            <Button
              variant="outlined"
              color="primary"
            >
              Video Lesson
            </Button>
          </a>
        </li>
        <li>
          <a href="https://www.codewars.com/kata/vowel-remover" target="_blank"> Vowel Remover</a>
          <a className="codewarsChallenge__videoUrl right" href='https://www.youtube.com/watch?v=kz5r8JNLB54' target="_blank">
            <Button
              variant="outlined"
              color="primary"
            >
              Video Lesson
            </Button>
          </a>
        </li>
        <li>
          <a href="https://www.codewars.com/kata/return-string-of-first-characters" target="_blank"> Return String of First Characters</a>
          <a className="codewarsChallenge__videoUrl right" href='https://www.youtube.com/watch?v=FgPwKuLN1wo' target="_blank">
            <Button
              variant="outlined"
              color="primary"
            >
              Video Lesson
            </Button>
          </a>
        </li>
      </ul>
      {/* </div> */}
      {/* <Button
        className='btn-purple get-started-btn'
        variant="contained"
        color="primary"
        onClick={() => window.location.href = '/profile'}
      >
        Go To Your Profile
      </Button> */}
    </Box>
  );

  const getStepContent = (step: number): JSX.Element => {
    switch (step) {
      case 0:
        return renderStep1();
      case 1:
        return renderStep2();
      case 2:
        return renderStep3();
      case 3:
        return renderStep4();
      case 4:
        return renderStep5();
      case 5:
        return renderStep6();
      default:
        return <Typography>Unknown step</Typography>;
    }
  };

  return (
    <Box sx={{
      maxWidth: 700,
      mx: 'auto',
      my: 4,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      className: 'sync-codewars-account'
    }}>
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => setShowToast(false)}
        message="Copied to clipboard"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />

      {hasUserInfo ? (
        renderStep6()
      ) : (
        <>
          {activeStep < 4 && (
            <h3 style={{ color: '#15154f' }}>Sync Your Codewars Account</h3>
          )}

          <Card sx={{ width: '100%', mb: 4 }}>
            <CardContent>
              {getStepContent(activeStep)}

              {/* {activeStep > 0 && ( // Conditionally render Previous button
            <Button
              className='btn-purple'
              disabled={isLoading}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Previous
            </Button>
          )} */}

              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Tooltip
                  title={
                    activeStep === 1
                      ? "Please check username and try again."
                      : activeStep === 4
                        ? "Make sure to update your Webhook URL and Secret Token."
                        : ""
                  }
                  disableHoverListener={!(
                    (activeStep === 1 && (!username || !!usernameError)) ||
                    (activeStep === 4 && !webhookIsConnected)
                  )}
                >
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      disabled={
                        (activeStep === 1 && (!username || !!usernameError)) ||
                        (activeStep === 4 && !webhookIsConnected)
                      }
                    >
                      {activeStep < 5 ? "Next" : "Go to Profile"}
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            </CardContent>
          </Card>

          <Stepper
            activeStep={activeStep}
            sx={{ width: '100%', mb: 4 }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel></StepLabel>
              </Step>
            ))}
          </Stepper>
        </>
      )}
    </Box>
  );

};

export default SyncCodewarsAccount;