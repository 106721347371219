import React, { useState, ChangeEvent, FocusEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { TextFieldProps } from '@material-ui/core';

interface NameInputProps {
  value: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  errorsArr: string[];
  setErrorsArr: React.Dispatch<React.SetStateAction<string[]>>;
}

const NameInput: React.FC<NameInputProps> = ({
  value, 
  handleChange, 
  errorsArr, 
  setErrorsArr
}) => {
  const [error, setError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>('');

  const validateName = (name: string): void => {
    if (name.length < 2) {
      setError(true);
      const tempErrorsArr = errorsArr.filter(i => i !== 'name');
      setErrorsArr([...tempErrorsArr, "name"]);
      setHelperText("Please provide a name of two characters or more.");
      return;
    } 
    
    setError(false);
    setHelperText(""); 
    const tempErrorsArr = errorsArr.filter(i => i !== 'name');
    setErrorsArr([...tempErrorsArr]);    
  };

  return (
    <Grid item xs={12}>
      <TextField
        autoComplete="name"
        name="name"
        variant="outlined"
        value={value}
        required
        fullWidth
        id="firstName"
        label="Name"
        error={error}
        helperText={helperText}
        onChange={handleChange}
        onBlur={(e: FocusEvent<HTMLInputElement>) => validateName(e.target.value)}
        autoFocus
      />
    </Grid>
  );
};

export default NameInput;