export const validateCodewarsUsername = async (codewarsUsername: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/codewars/${codewarsUsername}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      },
      body: JSON.stringify({codewarsUsername}),
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error validating codewars username:', error)
    throw error
  }
}

export const updateUserHonor = async (userId: string, username: string, codewarsid: string, honor: number) => {

try {
  const response = await fetch(`${process.env.REACT_APP_API}/users/${userId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`
    },
    body: JSON.stringify({
      honor, 
      username, 
      codewarsid 
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to update user honor');
  }

  const updatedUser = await response.json();

  return updatedUser; 
} catch (error) {
  console.error('Error updating user honor:', error);
  throw error; 
}
};

export const getCodewarsInfo = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/codewars/check-codewars`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching Codewars info: ${response.statusText}`);
    }

    const codewarsData = await response.json();
    return codewarsData;
  } catch (error) {
    console.error('Error in getCodewarsInfo:', error);
    throw error;
  }
};
