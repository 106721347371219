const postBattingCagePoints = async (userToken, levelCompleted, outs) => {
  const res = await fetch(`${process.env.REACT_APP_API}/battingCagePoints`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      levelCompleted,
      outs,
    }),
  });
};

export default postBattingCagePoints;