import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';
import ShadowBox from '../../../layoutComponents/shadowBox/ShadowBox';

import cssIntroChallenges from '../../../problemSets/cssIntroChallenges.json';

import './CssChallenges.scss';

const CssChallengeListItem = ({challenge}) => {

    const {name, difficulty, slug, videoUrl, id, completed, challenge_url } = challenge;
    return (
        <div className="cssChallengeListItem">

            <Link to={`/cssChallenges/${slug}`}>
                
                <Checkbox
                    color="primary"
                    disabled={!completed}
                    checked={completed}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                
                <span className="cssChallengeListItem__title">{name} </span>
            </Link>
            <span className="cssChallengeListItem__difficulty"><span>Difficulty:</span> {difficulty}</span>
            {videoUrl && 
                <a className="cssChallengeListItem__videoUrl" href={videoUrl} target="_blank">
                    <Button
                    variant="contained"
                    color="primary"
                    >
                        Video Lesson
                    </Button>
                </a>
            }
            {challenge.completed && <div><a href={challenge_url} target="_blank">Solution URL</a></div>}
        </div>
    )
}

const CssChallenges = ({playerInfo}) => {

    const [completedChallenges, setCompletedChallenges] = React.useState([]);

    useEffect(() => {
        if(playerInfo.id){
            fetch(`${process.env.REACT_APP_API}/cssChallenges/${playerInfo.uuid}`)
            .then(res => res.json())
            .then(data => {
                setCompletedChallenges(data)
            })
        }
    },[playerInfo])

    cssIntroChallenges.forEach(challenge => {
        challenge.completed = false;
        completedChallenges.forEach(completedChallenge => {
            if (completedChallenge.challenge_id === challenge.id) {
                challenge.completed = true;
                challenge.challenge_url = completedChallenge.challenge_url;
            }
        })
    })


  return (
    <ShadowBox className="cssChallenges" title="CssChallenges">
        {
        cssIntroChallenges.map(challenge => {
             return (<CssChallengeListItem challenge={challenge} />)
        })
        }
    </ShadowBox>
  )
}

export default CssChallenges;