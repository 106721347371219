import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Collapsible from "react-collapsible";
// import Checkbox from '@material-ui/core/Checkbox';
import { Button } from "@material-ui/core";

import ProblemTable from "../problemTable/ProblemTable";

import "./ProblemSet.scss";

const ProblemSetHeader = ({ title, completedCount, problemCount, id }) => {
  let history = useHistory();

  const editProblemSet = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`/problems/edit`);
  };

  return (
    <div className="problemSetHeader">
      <span className="problemSetHeader__title">{title}</span>
      <span className="problemSetHeader__count"> Problems: {problemCount}</span>
      <Button variant="contained" color="primary" onClick={(e) => editProblemSet(e)} className="problemSetHeader__edit">
        {" "}
        Edit Problem Set
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={(e) => editProblemSet(e)}
        className="problemSetHeader__assign"
      >
        Assign Problem Set
      </Button>
    </div>
  );
};


function ProblemSet({index, title, problems, handleRefresh}) {
  return (
    <Collapsible
      trigger={<ProblemSetHeader title={title} problemCount={problems.length} id={index} />}
      classParentString="challengeCollection"
      open={false}
    >
      <div className="challengesContainer">
        {problems.length ? (
          <ProblemTable problems={problems} refreshProblems={handleRefresh} />
        ) : (
          <div style={{ textAlign: "center" }}>No problems found</div>
        )}
      </div>
    </Collapsible>
  );
}

export default ProblemSet;
