import React, { useEffect, useState, useCallback } from "react";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ShadowBox from "../../layoutComponents/shadowBox/ShadowBox";
import CommitsLog from "../../components/commitsLog/CommitsLog";
import PortfolioDisplay from "../../components/portfolioDisplay/PortfolioDisplay";
import PortfolioProjectForm from "../../components/portfolioProjectForm/PortfolioProjectForm"


import "./StudentDetailSubviews.scss";
import "./Portfolio.scss";

function Portfolio({ playerInfo, isProfile }) {
  const [portfolioData, setPortfolioData] = React.useState({});
  const [showEditForm, setShowEditForm] = React.useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [successfulUpdate, setSuccessfulUpdate] = useState("");
  const [todos, setTodos] = useState([]);

  const errorElement = <Alert severity='error'>{snackbarMessage}</Alert>;
  const successElement = <Alert>Portfolio was updated successfully!</Alert>;

  const fetchTodos = useCallback(async () => {
    if(!isProfile) return;


    const res = await fetch(`${process.env.REACT_APP_API}/todos`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    if (res.ok) {
      const json = await res.json();
      setTodos(json);
    }
  }, [setTodos]);

  useEffect(() => {
    if (playerInfo.uuid) {
      const api = `${process.env.REACT_APP_API}/portfolios/${playerInfo.uuid}`;
      fetch(api)
        .then((res) => res.json())
        .then((result) => {
          if (result) {
            setPortfolioData(result)
          } else {
            setPortfolioData({})
            setShowEditForm(true)
          }
        });
    }
  }, [playerInfo, showEditForm]);

  return (
    <div className="portfolio">
      <Snackbar
        open={showSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
      >
        {successfulUpdate ? successElement : errorElement}
      </Snackbar>
      {portfolioData && Object.values(portfolioData).length > 0 && (showEditForm == false) && (
        <ShadowBox>
          <PortfolioDisplay
            portfolioData={portfolioData}
            setShowEditForm={setShowEditForm}
            showEditForm={showEditForm}
            setSuccessfulUpdate={setSuccessfulUpdate}
          />
        </ShadowBox>
      )}
        {(playerInfo?.uuid && showEditForm) && <PortfolioProjectForm userId={playerInfo.uuid} setShowEditForm={setShowEditForm} setShowSnackbar={setShowSnackbar} snackbarMessage={snackbarMessage} setSnackbarMessage={setSnackbarMessage} setSuccessfulUpdate={setSuccessfulUpdate} />}
      {/* <ShadowBox title="Todo List">
        <TodoList
          todos={todos}
          fetchTodos={fetchTodos}
          ghName={playerInfo.github_username}
          isProfile
        />
      </ShadowBox> */}
      <ShadowBox title="Commit Tracker">
        <CommitsLog playerInfo={playerInfo} fetchTodos={fetchTodos} />
      </ShadowBox>
    </div>
  );
}

export default Portfolio;
