import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Button from "@material-ui/core/Button";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBoxOutlined";
import { useState, useEffect, Fragment } from "react";

export default function CommitDisplay({ commit, date, index, fetchTodos }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const commitMessage = commit.commit_message.split('\n')
  const commitTitle = commitMessage[0]
  const commitDescription = commitMessage.slice(1)

  useEffect(() => {
    if (!open) {
      return;
    }
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_API}/githubResources/commits/${commit.id}/suggestions`
    ).then((res) =>
      res.json().then(
        (result) => {
          setSuggestions(result);
          setLoading(false);
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }, [open, commit.id]);

  const addTodo = async function (item) {
    try {
      const res = await fetch(`${process.env.REACT_APP_API}/todos/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          todo: item.suggestion,
          repo_name: commit.repo_name,
        }),
      });
      if (!res.ok) {
        throw new Error("Network error adding todo");
      }
      fetchTodos();
      //refetch suggestions to update todo "added" status
    } catch (err) {
      alert(err);
    }
    return;
  };

  const even = index % 2 === 0;
  return (
    <Fragment key={index}>
      <TableRow className={even ? "even" : "odd"}>
        <TableCell>{date}</TableCell>
        <TableCell>{commit.repo_name}</TableCell>
        <TableCell>
          <a href={commit.commit_url} target="blank">
            {commitTitle}
          </a>
          {showMore && (
            commitDescription.map((commit, index) => {
              return  <div>{commit}</div>
            })
          )}
        </TableCell>
        <TableCell>
          {commitDescription.length && <Button
            onClick={() => setShowMore((prev) => !prev)}
          >
            {showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>}
        </TableCell>
        <TableCell>{commit.score || "N/A"}</TableCell>
        <TableCell padding="checkbox">
          <Button
            style={{
              width: "2em",
              fontWeight: "bold",
              fontSize: "1.5em",
            }}
            onClick={() => setOpen((o) => !o)}>
            {open ? "-" : "+"}
          </Button>
        </TableCell>
      </TableRow>
      {open && (
        <TableRow className="feedback">
          <TableCell colSpan="5" size="medium">
            {loading ? (
              "Loading..."
            ) : suggestions && suggestions.length > 0 ? (
              <ul>
                {suggestions.map((item, i) => (
                  <li key={i}>
                    {item.suggestion}
                    <ul
                      style={{
                        listStyleType: "none",
                        display: "flex",
                        margin: "1em 0 2em 0",
                        padding: 0,
                        gap: "1em",
                      }}>
                      <li>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={item.has_a_todo}
                          onClick={() => addTodo(item)}
                          startIcon={
                            item.has_a_todo ? (
                              <CheckBoxIcon />
                            ) : (
                              <CheckBoxOutlineBlankIcon />
                            )
                          }>
                          Make a TODO
                        </Button>
                      </li>
                      <li>
                        <Button variant="contained" color="primary">
                          Generate Code
                        </Button>
                      </li>
                      <li>
                        <Button variant="contained" color="primary">
                          Open a PR
                        </Button>
                      </li>
                    </ul>
                  </li>
                ))}
              </ul>
            ) : (
              <div style={{ textAlign: "center" }}>
                <span>No feedback available</span>
                <br />
                <Button variant="contained" color="primary">
                  Request review
                </Button>
              </div>
            )}
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
}
