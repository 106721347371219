import React, { useEffect, useState } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";

import Paper from "@material-ui/core/Paper";

import TrackProjectInstructions from "../trackProjectInstructions/TrackProjectInstructions";

import "./CommitsLog.scss";
import CommitDisplay from "./CommitDisplay";

function sortByNew(array) {
  array.sort((a, b) => {
    if (a.created_at < b.created_at) {
      return 1;
    } else if (a.created_at > b.created_at) {
      return -1;
    }
    return 0;
  });
}

function CommitsLog({ playerInfo, fetchTodos }) {
  const [commits, setCommits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showInstructions, setShowInstructions] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCommits, setTotalCommits] = useState(0);
  const commitsPerPage = 10; // Number of commits per page

  useEffect(() => {
    const fetchCommits = async () => {
      setLoading(true);
      try {
        // Fetch total number of commits
        const totalResponse = await fetch(
          `${process.env.REACT_APP_API}/githubResources/${playerInfo.github_username}/commits/count`
        );
        const totalResult = await totalResponse.json();
        setTotalCommits(totalResult.total);

        // Fetch commits for the current page
        const response = await fetch(
          `${process.env.REACT_APP_API}/githubResources/${playerInfo.github_username}/commits?limit=${commitsPerPage}&offset=${(currentPage - 1) * commitsPerPage}`
        );
        const result = await response.json();
        sortByNew(result);
        setCommits(result);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCommits();
  }, [playerInfo, currentPage]);

  const formatDate = (date) => {
    date = date.split("T")[0].split("-");
    let month = date[1];
    let day = date[2];
    if (month[0] === "0") month = month[1];
    if (day[0] === "0") day = day[1];

    return month + "/" + day;
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const maxPage = Math.ceil(totalCommits / commitsPerPage);

  return (
    <div className="commitsLog">
      <div className="commitsLog__header">
        {!loading && commits.length > 0 && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => setShowInstructions(!showInstructions)}>
            How to Add a Project
          </Button>
        )}
      </div>
      {loading && <div className="commitsLog__loadingView">Loading...</div>}
      {!loading && (commits.length < 1 || showInstructions) && (
        <TrackProjectInstructions />
      )}
      {commits.length > 0 && (
        <TableContainer component={Paper}>
          <Table size="small" data-testid="commitsLog__tableOfCommits">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Repository</TableCell>
                <TableCell>Commit Message</TableCell>
                <TableCell></TableCell>
                <TableCell>Score</TableCell>
                <TableCell padding="checkbox"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {commits.map((commit, index) => {
                let date = formatDate(commit.created_at);

                return (
                  <CommitDisplay
                    commit={commit}
                    date={date}
                    index={index}
                    fetchTodos={fetchTodos}
                  />
                );
              })}
            </TableBody>
          </Table>
          <div className="pagination">
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <span>Page {currentPage} of {maxPage}</span>
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage >= maxPage}
            >
              Next
            </Button>
        </div>
        </TableContainer>
      )}
    </div>
  );
}

export default CommitsLog;
