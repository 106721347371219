import React, {useState, useEffect} from 'react';

import badgeData from '../../data/badges.json';
import {useFetchBadges} from '../../hooks/useFetchBadges';

import ShadowBox from '../../layoutComponents/shadowBox/ShadowBox';
import SingleBadge from '../singleBadge/SingleBadge';


import './BadgeList.scss';

function BadgeList({userId}) {
    const {badges, isLoading, error } = useFetchBadges(userId); 

    return (
        <ShadowBox  className="badgeList" title="Badges" loading={isLoading} error={error}>
            <div className="badgeList__container">
                {badges && Object.entries(badges).map((badge, index) => {

                    if(badge[0] === 'id' || badge[0] === 'user_uuid')
                        return;

                    let badgeKey = badge[0];
                    
                    if(!badge[1])
                        badgeKey += "Gray"

                    return (
                        <SingleBadge key={index} badge={badgeData[badgeKey]} />
                    )
                })}
            </div>
        </ShadowBox>
    );
}

export default BadgeList;