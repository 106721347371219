import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Alert from "@mui/material/Alert";

const AddProblem = ({ handleRefresh }) => {
  const [url, setUrl] = useState("");
  const [setName, setSetName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(""); // State for success message

  const handleSubmit = async () => {
    setLoading(true);
    setSuccess(""); // Clear any previous success messages
    setError(""); // Clear any previous errors

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        body: JSON.stringify({ url, setName }),
      };

      const res = await fetch(`${process.env.REACT_APP_API}/problems`, requestOptions);

      if (!res.ok) {
        const errRes = await res.json();
        throw new Error(errRes.message || `Error: ${res.status}`);
      }
      const result = await res.json();

      setLoading(false);

      handleRefresh();
      setUrl("");
      setSetName("");
      setSuccess("Problem added successfully!");
    } catch (error) {
      setLoading(false);
      setError(error.message || "Failed to add problem. Please try again.");
    }
  };

  return (
    <div className="addProblem">
      <div className="container">
        <h3>Add Problem</h3>
        {error && (
          <div className="error-message" style={{ marginBottom: "15px" }}>
            <Alert severity="error">{error}</Alert>
          </div>
        )}
        {success && (
          <div className="success-message" style={{ marginBottom: "15px" }}>
            <Alert severity="success">{success}</Alert>
          </div>
        )}
        <Grid container>
          <Grid item xs={12} spacing={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="set-name-input"
              label="Set Name"
              name="setName"
              value={setName}
              onChange={(e) => setSetName(e.target.value)}
            />
            <TextField
              fullWidth
              variant="outlined"
              id="url-input"
              label="Problem Set URL"
              name="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </Grid>
        </Grid>
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={loading}
        >
          Add
        </LoadingButton>
      </div>
    </div>
  );
};

export default AddProblem;
