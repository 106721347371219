import React, { isValidElement, useState, ChangeEvent, FocusEvent } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

interface OrgConfig {
  orgName?: string;
  emailSuffix?: string;
  reqOrgEmail?: boolean;
  [key: string]: any;
}

interface EmailInputProps {
  value: string;
  orgConfig: OrgConfig;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  errorsArr: string[];
  setErrorsArr: React.Dispatch<React.SetStateAction<string[]>>;
}

const EmailInput: React.FC<EmailInputProps> = ({
  value,
  orgConfig,
  handleChange,
  errorsArr,
  setErrorsArr,
}) => {
  const { orgName = "", emailSuffix = "", reqOrgEmail = false } = orgConfig;
  const [error, setError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>("");

  const formattedOrgName = orgName
    ? orgName[0].toUpperCase() + orgName.slice(1)
    : "";

  const emailPlaceholder =
    orgName !== "codetrack" && reqOrgEmail
      ? `${formattedOrgName} Email Address`
      : "Email Address";

  const isValidEmail = (email: string): RegExpMatchArray | null => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const needsToMatchOrg = (email: string): boolean => {
    return email.split("@")[1]?.toLowerCase() === emailSuffix.toLowerCase();
  };

  const validateEmail = (email: string): void => {
    if (!isValidEmail(email)) {
      setError(true);
      setHelperText("This is not a valid email.");
      const tempErrorsArr = errorsArr.filter((i) => i !== "email");
      setErrorsArr([...tempErrorsArr, "email"]);
      return;
    }

    if (reqOrgEmail && !needsToMatchOrg(email)) {
      setError(true);
      setHelperText(`Please use the email provided by ${orgName}.`);
      const tempErrorsArr = errorsArr.filter((i) => i !== "email");
      setErrorsArr([...tempErrorsArr, "email"]);
      return;
    }

    setError(false);
    setHelperText("");
    const tempErrorsArr = errorsArr.filter((i) => i !== "email");
    setErrorsArr([...tempErrorsArr]);
  };

  return (
    <Grid item xs={12}>
      <TextField
        variant="outlined"
        required
        fullWidth
        id="email"
        label={emailPlaceholder}
        name="email"
        autoComplete="email"
        error={error}
        helperText={helperText}
        onChange={handleChange}
        onBlur={(e: FocusEvent<HTMLInputElement>) => validateEmail(e.target.value)}
        value={value}
      />
    </Grid>
  );
};

export default EmailInput;