import React, { useState, useEffect } from "react";

// MUI Components
import { TextField, Typography, Button, Box, CircularProgress } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

// Layout
import ShadowBox from "../../layoutComponents/shadowBox/ShadowBox";
import Modal from "../../utilComponents/modal/Modal";

// Types and interfaces
interface LeetCodeIntegrationProps {
  userId: string;
  currentLeetcodeId: string | undefined;
  displayToast: (message: string) => void;
}

interface LeetCodeUserInfo {
  username: string;
  realName: string;
  avatar: string;
  totalSolved: number;
  easySolved: number;
  mediumSolved: number;
  hardSolved: number;
}

const LeetCodeIntegration: React.FC<LeetCodeIntegrationProps> = ({ userId, currentLeetcodeId, displayToast }) => {
  const [leetcodeUsername, setLeetcodeUsername] = useState<string>(currentLeetcodeId || "");
  const [linkedUsername, setLinkedUsername] = useState<string | undefined>(currentLeetcodeId);
  const [loading, setLoading] = useState<boolean>(false);
  const [validating, setValidating] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<LeetCodeUserInfo | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLeetcodeUsername(e.target.value);
    setError(null);
  };

  const validateLeetCodeAccount = async () => {
    if (!leetcodeUsername.trim()) {
      setError("Please enter a LeetCode username");
      return;
    }

    setValidating(true);
    setError(null);

    try {
      const response = await fetch(process.env.REACT_APP_API + `/leetcode/validate/` + leetcodeUsername);
      const data = await response.json();
      const user = data?.leetCodeAccount?.data?.matchedUser;

      if (!user) {
        throw new Error("LeetCode user not found");
      }

      const submitStats = user.submitStats.acSubmissionNum;

      const userInfo: LeetCodeUserInfo = {
        username: user.username,
        realName: user.profile.realName || "Not provided",
        avatar: user.profile.userAvatar,
        totalSolved: submitStats.find((stat: any) => stat.difficulty === "All").count || 0,
        easySolved: submitStats.find((stat: any) => stat.difficulty === "Easy").count || 0,
        mediumSolved: submitStats.find((stat: any) => stat.difficulty === "Medium").count || 0,
        hardSolved: submitStats.find((stat: any) => stat.difficulty === "Hard").count || 0,
      };

      setUserInfo(userInfo);
      setShowConfirmModal(true);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : "Failed to validate LeetCode account";
      setError(errorMessage);
    } finally {
      setValidating(false);
    }
  };

  // Add effect for body scroll locking
  useEffect(() => {
    if (showConfirmModal) {
      // Save current scroll position
      const scrollY = window.scrollY;

      // Add styles to body to prevent scrolling
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = "100%";
      document.body.style.overflowY = "scroll";
    } else {
      // Get scroll position from body top property
      const scrollY = document.body.style.top;

      // Remove styles from body
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      document.body.style.overflowY = "";

      // Restore scroll position
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }

    // Clean up function to ensure we remove styles when component unmounts
    return () => {
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      document.body.style.overflowY = "";
    };
  }, [showConfirmModal]);

  const linkLeetCodeAccount = async () => {
    setLoading(true);

    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        throw new Error("Authentication required");
      }

      const response = await fetch(`${process.env.REACT_APP_API}/leetcode/${leetcodeUsername}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          leetcodeid: leetcodeUsername,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to link LeetCode account");
      }
      displayToast("LeetCode account linked successfully");
      setShowConfirmModal(false);
      setLinkedUsername(leetcodeUsername);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : "Failed to link LeetCode account";
      setError(errorMessage);
      displayToast(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal showModal={showConfirmModal} setShowModal={setShowConfirmModal} title="Confirm LeetCode Account">
        {userInfo && (
          <Box sx={{ padding: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              {userInfo.avatar && (
                <img
                  src={userInfo.avatar}
                  alt={`${userInfo.username}'s profile`}
                  style={{ width: 60, height: 60, borderRadius: "50%", marginRight: 16 }}
                />
              )}
              <Box>
                <Typography variant="h6">{userInfo.username}</Typography>
                {userInfo.realName && (
                  <Typography variant="body2" color="textSecondary">
                    {userInfo.realName}
                  </Typography>
                )}
              </Box>
            </Box>

            <Typography variant="subtitle1" gutterBottom>
              Problems Solved Statistics:
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h6" color="primary">
                  {userInfo.totalSolved}
                </Typography>
                <Typography variant="body2">Total</Typography>
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h6" sx={{ color: "success.main" }}>
                  {userInfo.easySolved}
                </Typography>
                <Typography variant="body2">Easy</Typography>
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h6" sx={{ color: "warning.main" }}>
                  {userInfo.mediumSolved}
                </Typography>
                <Typography variant="body2">Medium</Typography>
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h6" sx={{ color: "error.main" }}>
                  {userInfo.hardSolved}
                </Typography>
                <Typography variant="body2">Hard</Typography>
              </Box>
            </Box>

            <Typography variant="body2" gutterBottom>
              Is this the correct LeetCode account you want to link?
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 2 }}>
              <Button variant="outlined" onClick={() => setShowConfirmModal(false)}>
                Cancel
              </Button>
              <LoadingButton variant="contained" color="primary" onClick={linkLeetCodeAccount} loading={loading}>
                Confirm & Link Account
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Modal>

      <ShadowBox title="LeetCode Integration">
        <Typography variant="body1" paragraph>
          Connect your LeetCode account to track your problem-solving progress and earn points on CodeTrack.
        </Typography>

        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2, mt: 2 }}>
          <TextField
            variant="outlined"
            id="leetcode"
            label="LeetCode Username"
            name="leetcode"
            value={leetcodeUsername}
            onChange={handleInputChange}
            error={!!error}
            helperText={error || ""}
            // Disable if we're validating OR if there's already a linked username
            disabled={validating || !!linkedUsername}
          />

          {/* Only show the "Link Account" button if there's NO existing LeetCode ID. */}
          {!linkedUsername && (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={validateLeetCodeAccount}
              loading={validating}
              disabled={!leetcodeUsername.trim()}
              sx={{ height: 56 }}
            >
              Link Account
            </LoadingButton>
          )}
        </Box>

        {linkedUsername && (
          <Typography variant="body2" sx={{ mt: 2, color: "success.main" }}>
            Currently linked to LeetCode account: <strong>{linkedUsername}</strong>
          </Typography>
        )}
      </ShadowBox>
    </>
  );
};

export default LeetCodeIntegration;
