import { useState, useEffect } from "react";

function useSessionStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading sessionStorage key “${key}”:`, error);
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      if (storedValue === null || storedValue === undefined) {
        sessionStorage.removeItem(key);
      } else {
        sessionStorage.setItem(key, JSON.stringify(storedValue));
      }
    } catch (error) {
      console.warn(`Error writing sessionStorage key “${key}”:`, error);
    }
  }, [key, storedValue]);

  const removeSessionKey = () => {
    try {
      sessionStorage.removeItem(key);
      // Set our local state to null so it won't re-save on the next render
      setStoredValue(null as unknown as T);
    } catch (error) {
      console.warn(`Error removing sessionStorage key “${key}”:`, error);
    }
  };

  return [storedValue, setStoredValue, removeSessionKey] as const;
}

export default useSessionStorage;
