// api/userService.ts
import { PlayerInfo } from "../components/playerCard/PlayerCard";

export interface UserResponse {
  status: string;
  users: PlayerInfo[];
  count: number;
  employed: boolean;
}

export interface UserProfileData {
  uuid: string;
  name: string;
  email: string;
  cohort: string;
  username?: string; // Codewars
  github_username?: string;
  role?: string;
  [key: string]: any; // For any additional properties
}

/**
 * Fetch the user profile data
 * @param accessToken - JWT access token
 * @returns User profile data
 */
export const fetchUserProfile = async (accessToken: string): Promise<UserProfileData> => {
  const requestOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const response = await fetch(
    `${process.env.REACT_APP_API}/users/profile`, 
    requestOptions
  );
  
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Failed to fetch user profile");
  }
  
  return await response.json();
};

/**
 * Update user profile information
 * @param userId - User UUID
 * @param userData - User data to update
 * @param accessToken - JWT access token
 * @returns Updated user data
 */
export const updateUserProfile = async (
  userId: string, 
  userData: Partial<UserProfileData>, 
  accessToken: string
): Promise<UserProfileData> => {
  const requestOptions: RequestInit = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(userData),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API}/users/${userId}`,
    requestOptions
  );

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Failed to update user");
  }

  return await response.json();
};

/**
 * Delete a user account
 * @param userId - User UUID
 * @param accessToken - JWT access token
 * @returns Fetch response object
 */
export const deleteUserAccount = async (
  userId: string, 
  accessToken: string
): Promise<Response> => {
  const requestOptions: RequestInit = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const response = await fetch(
    `${process.env.REACT_APP_API}/users/${userId}`,
    requestOptions
  );
  
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Failed to delete user account");
  }
  
  return response;
};

/**
 * Fetch users of an organization with pagination and filtering options
 * @param organization - Organization identifier
 * @param page - Page number for pagination (default: 1)
 * @param cohort - Cohort identifier or array of cohorts
 * @param employed - Filter by employment status
 * @param searchTerm - Search term for filtering by name
 * @param limit - Number of results per page
 * @param sortBy - Field to sort results by
 * @returns User data response
 */
export const fetchUsersOfOrganizationWithPagination = async (
  organization: string,
  page: number = 1,
  cohort?: string | string[], 
  employed?: boolean,
  searchTerm?: string,
  limit?: number,
  sortBy?: string
): Promise<UserResponse> => {
  try {
    const url = new URL(`${process.env.REACT_APP_API}/users`);
    
    // Add base parameters
    url.searchParams.set("organization", organization);
    url.searchParams.set("page", page.toString());

    // Handle name filter parameter
    if (searchTerm) {
      url.searchParams.set("nameFilter", searchTerm);
    }
    
    // Handle employed parameter
    if (employed !== undefined) {
      url.searchParams.set("employed", employed.toString());
    }

    if (limit) {
      url.searchParams.set("limit", limit.toString());
    }

    if (sortBy) {
      url.searchParams.set("sortBy", sortBy);
    }
    
    // Handle cohort parameter
    if (cohort) {
      // If cohort is an array, join with comma
      const cohortValue = Array.isArray(cohort) ? cohort.join(',') : cohort;
      url.searchParams.set("cohort", cohortValue);
    }
    
    // Get any existing query parameters from the current URL
    const currentParams = new URLSearchParams(window.location.search);
    
    for (const [key, value] of currentParams) {
      // Skip parameters we've already set
      if (!['organization', 'page', 'cohort', 'nameFilter', 'employed', 'limit', 'sortBy'].includes(key)) {
        url.searchParams.set(key, value);
      }
    }
    
    const response = await fetch(url.toString());
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data: UserResponse = await response.json();
    
    if (data.status !== "success") {
      throw new Error(data.status || "Failed to fetch users");
    }
    
    return data;
    
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};