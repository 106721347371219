import React from 'react';

import ShadowBox from '../../layoutComponents/shadowBox/ShadowBox';
import PlayerStats from '../playerStats/PlayerStats';

import './PlayerDetailCard.scss';

function PlayerDetailCard({playerInfo}) {
    return (
        <ShadowBox title={playerInfo.name} className="playerDetailCard" displayDescription="space-between">
                <div>
                    <div className="playerDetailCard__totalPoints">
                        {playerInfo.total_points }
                    </div>
                    {playerInfo.cohort &&
                        <div className="playerDetailCard__cohort">
                            Class: {playerInfo.cohort}
                        </div>
                    }
                </div>
                <div className="playerDetailCard__stats">
                    <PlayerStats playerInfo={playerInfo} title="Points" type="codewars"/> 
                    <PlayerStats playerInfo={playerInfo} title="Commits" /> 
                </div>            
        </ShadowBox>
    );
}

export default PlayerDetailCard;