import React, {useState} from 'react';

import SpinningLoader from '../spinningLoader/SpinningLoader';
import ErrorView from '../errorView/ErrorView';

import useSessionStorage from "../../hooks/useSessionStorage";



import './ShadowBox.scss';

type ShadowBoxProps = {
    title?: string;
    cta?: React.ReactNode;
    children: React.ReactNode;
    className?: string;
    displayDescription?: "flex" | "block" | "vertical-flex" | "space-between";
    error?: Error | null;
    loading?: boolean;
}

const ShadowBox = ({title, cta, children, className, displayDescription="block", error, loading}: ShadowBoxProps) =>  {

    const displayStyles = {
        "flex": {
            "display": "flex"
        }, 
        "block": {
            "display": "block",
        }, 
        "vertical-flex": {
            "display": "flex",
            "alignItems": "center"
        }, 
        "space-between": {
            "display": "flex",
            "justifyContent": "space-between",
            "alignItems": "center"
        }
    }

    const displayStyle = displayStyles[displayDescription];

    return (
        <div className={className + " shadowBox"}>
            <div className={className + " shadowBox__header"}>
                {title && <div className="shadowBox__title">{title}</div>}
                <div className="shadowBox__cta">{cta}</div>
            </div> 
            {error && <ErrorView error={error} />}
            {loading && <div className="shadowBox__loading">
                <SpinningLoader />    
            </div>}
            <div style={displayStyle}>
                {children}
            </div>
        </div>
    );
}

export default ShadowBox;