import {useState, useEffect} from 'react';

import { fetchLeetcodeStats } from '../services/leetcodeService';

export function useFetchLeetcode (uuid: string){
    const [leetcodeData, setLeetcodeData] = useState<any>({
        total: 0,
        problems : {
            easy: 0,
            medium: 0,
            hard: 0
        }
    });
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchLeetcodeData = async () => {
            try {
                if(!uuid){
                    setLoading(false);
                    return;
                }
                const data = await fetchLeetcodeStats(uuid);
                setLeetcodeData(data);
                setLoading(false);
            } catch (error) {
                setError(error instanceof Error ? error : new Error('An unknown error occurred'));
                setLoading(false);
            }
        };
        fetchLeetcodeData();
    }, [uuid]);

    return {leetcodeData, loading, error};
}