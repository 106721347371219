import React ,{ useEffect, useRef } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import './CodilityChallengesCollection.scss'

import Collapsible from 'react-collapsible';

const CodilityChallenge = ({name, url, difficulty, userId, problemId}) => {

    const [solutionUrl, setSolutionUrl] = React.useState('');

    const solutionUrlRef = useRef();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API}/codility/${userId}/${problemId}`)
        .then(response => response.json())
        .then(data =>{
            if(data)
                setSolutionUrl(data.solutionUrl);
        });
    }, [userId, problemId]);

    const validateSolutionUrl = (url) => {
        let regexp = /https:\/\/app.codility.com\/demo\/results\/training([A-Z0-9]*)-([A-Z0-9]*)\//g;

        return regexp.test(url) ? true : false;
    }

    const submitSolution = (e) => {

        let tempUrl = solutionUrlRef.current.value;

        if(!validateSolutionUrl(tempUrl)){
           console.log("invalid url");
           return;
        }

        solutionUrlRef.current.value = '';

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userId: userId, problemId: problemId, solutionUrl: tempUrl })
        };

        fetch(`${process.env.REACT_APP_API}/codility`, requestOptions)
        .then(response => response.json())
        .then(data =>{
            setSolutionUrl(tempUrl);
        });
         
    }

    return (
        <div className="codilityChallenge">
            <div>
                <a href={url} target="_blank">
                    <Checkbox
                        color="primary"
                        onClick={null}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        checked={!!solutionUrl}
                        disabled
                    />
                    <span className="codeChallenge__title">{name} </span>
                    <span className="codeChallenge__rank">Difficulty: {difficulty}</span>
                </a>
            </div>
            <div className='left'>
                {solutionUrl && 
                    <a href={solutionUrl} target="_blank"> My Solution </a>
                }
                {!solutionUrl && 
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            variant="outlined" 
                            placeholder="https://app.codility.com/demo/results/trainingZMPP63-KX2/"
                            inputRef={solutionUrlRef}
                            style={{ marginRight: '10px' }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => submitSolution(e)}
                        >
                            Submit
                        </Button>
                    </div>
                }
            </div>
        </div>
    )
}

const ProblemSetHeader = ({title, completedCount, problemCount}) => {
    //TODO find completed count of Codility Problems
    completedCount = completedCount || 0;
    return (
        <div className="problemSetHeader">
            <span className="problemSetHeader__title">{title}</span>
            <span className="problemSetHeader__count"> Complete: {completedCount}/{problemCount}</span>
        </div>
    )
}

const CodilityChallengesCollection = ({userId, problemSet, selectedProblemSet = []}) => {
    
    return (
        <Collapsible 
        trigger={<ProblemSetHeader title={problemSet} problemCount={selectedProblemSet.length}/>} 
        classParentString="challengeCollection"
        open={true}
        >
            <div className="codilityChallengesCollection">
                {selectedProblemSet.map(problem => {
                    return <CodilityChallenge userId={userId} problemId={problem.id} name={problem.title} difficulty={problem.difficulty} url={problem.url} />
                })}
            </div>
        </Collapsible>
    );
}

export default CodilityChallengesCollection;