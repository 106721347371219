// DeleteAccount.tsx
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Typography } from "@mui/material";
import ShadowBox from "../../layoutComponents/shadowBox/ShadowBox";
import Modal from "../../utilComponents/modal/Modal";
import { deleteUserAccount } from "../../services/usersService";

interface DeleteAccountProps {
  userId: string;
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  displayToast: (message: string) => void;
}

const DeleteAccount: React.FC<DeleteAccountProps> = ({ userId, setLoggedIn, displayToast }) => {
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleDeleteClick = async (): Promise<void> => {
    setLoading(true);
    
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        throw new Error("Authentication required");
      }
      
      const response = await deleteUserAccount(userId, accessToken);
      
      if (response.ok) {
        displayToast("Your account has been deleted.");
        setLoggedIn(false);
        localStorage.removeItem("accessToken");
        history.push("/login");
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete user");
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : "An error occurred";
      displayToast(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        title="Delete Account?"
      >
        <Typography component="p">
          <strong>Warning: </strong>Deleting your account cannot be undone. This
          action is permanent. Are you sure you want to delete your account?
        </Typography>
        <hr />
        <div className="deleteAcct__submit">
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => setShowDeleteModal(false)}
          >
            Go back
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="error"
            onClick={handleDeleteClick}
            loading={loading}
          >
            Delete my account
          </LoadingButton>
        </div>
      </Modal>

      <ShadowBox title="Delete Account">
        <Typography component="p">
          We're sad to see you go! When you delete your account all of your data will be
          deleted. We do not keep any records. If you are sure you want to delete your
          account, you can do so below.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            onClick={() => setShowDeleteModal(true)}
            sx={{ 
              backgroundColor: 'red',
              '&:hover': {
                backgroundColor: 'darkred',
              },
            }}
          >
            Delete my account
          </LoadingButton>
        </Box>
      </ShadowBox>
    </>
  );
};

export default DeleteAccount;