import React, { useState } from "react";
import { useHistory } from 'react-router-dom';

import { Button } from "@material-ui/core";
import BattingCageModal from "../battingCageModal";
import BattingCageIntroModal from "../battingCageIntroModal";


import '../howDoesItWork/howDoesItWork.scss';

interface LeftContentProps {
    onLearnMoreClick: () => void;
}

interface RightContentProps {
    onSyncClick: () => void;
}

const LeftContent: React.FC<LeftContentProps> = ({ onLearnMoreClick }) => {
    return (
        <div className="howDoesItWork__left">
            <div className="howDoesItWork__imageContainer">
                <div className="howDoesItWork__circularBorder">
                    <img src="/Group 624918.png" alt="Batting Cage" />
                </div>
            </div>
            <div className="howDoesItWork__text">
                <h4 className="howDoesItWork__title">Batting Cage</h4>
                <p className="howDoesItWork__description">
                    Solve problems in one line of code using any valid JavaScript. Practice problem solving, critical thinking and fluency with code.  
                </p>
            </div>
            <Button className="btn-purple" onClick={onLearnMoreClick}>Learn More</Button>
        </div>
    );
};

const RightContent: React.FC<RightContentProps> = ({ onSyncClick }) => {
    return (
        <div className="howDoesItWork__right">
            <div className="howDoesItWork__imageContainer">
                <div className="howDoesItWork__imagesRow">
                    <img src="/Vector.png" alt="1" />
                </div>
                <div className="howDoesItWork__imagesRow">
                    <img src="/Group 624916.png" alt="2" />
                </div>
                <div className="howDoesItWork__imagesRow">
                    <img src="/Subtract4.png" alt="3" />
                </div>
            </div>
            <div className="howDoesItWork__text">
                <h4 className="howDoesItWork__title">Sync Codewars Account</h4>
                <p className="howDoesItWork__description">
                    Codewars is a code challenge platform. Connect your account and earn points every time you solve a problem.
                </p>
            </div>
            <Button className="btn-purple" onClick={onSyncClick}>Sync</Button>
        </div>
    );
};

const HowDoesItWork: React.FC = () => {
    const history = useHistory();
    const [showBattingCageModal, setShowBattingCageModal] = useState(false);
    const [showBattingCageIntroModal, setShowBattingCageIntroModal] = useState(false);

    const handleSyncClick = () => {
        history.push('/syncCodewarsAccount');
    };

    const handleLearnMoreClick = () => {
        setShowBattingCageModal(false);
        setShowBattingCageIntroModal(true);
    };

    return (
        <div className="howDoesItWork">
            <div className="howDoesItWork__top">
                <h3 className="howDoesItWork__heading">How Does It Work?</h3>
                <p className="howDoesItWork__subheading">
                    Sync your CodeTrack account with one of the products below. Solve code challenges to earn your first points!
                </p>
            </div>

            <div className="howDoesItWork__bottom">
                <LeftContent onLearnMoreClick={ () => setShowBattingCageModal(true)} />
                <div className="howDoesItWork__divider"></div>
                <RightContent onSyncClick={ () => handleSyncClick()} />
            </div>

            <BattingCageModal
                showModal={showBattingCageModal}
                setShowModal={setShowBattingCageModal}
                learnMoreClick = {handleLearnMoreClick}
            />

            <BattingCageIntroModal
                showModal={showBattingCageIntroModal}
                setShowModal={setShowBattingCageIntroModal}
            />
        </div>
    );
};

export default HowDoesItWork;
