import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";

//Services
import {fetchUsersOfOrganizationWithPagination} from '../../services/usersService';

//COMPONENTS
import { PlayerInfo } from "../playerCard/PlayerCard";
import LoadingCards from "../loadingCards/LoadingCards";
import PlayerCard from "../playerCard/PlayerCard";
import EmptyView from "../emptyView/EmptyView";

//STYLES
import "./TopScoresCollection.scss";

const emptyViewStyles = {
  fontSize: "2em",
  height: "100%"
};

interface TopScoresCollectionProps {
  subdomain: string;
  location: { search: string };
  loading?: boolean;
  fellows: PlayerInfo[];
}

function TopScoresCollection({ subdomain, location, fellows }: TopScoresCollectionProps) {
  const [topUsers, setTopUsers] = useState<PlayerInfo[]>([]); 
  const [loading, setLoading] = useState(true);

  async function fetchTopUsers(organization: string) { 
    let cohort: string[] = [];
    const parsed = queryString.parse(location.search);
    if (parsed.cohort && typeof parsed.cohort === 'string') {
      cohort = parsed.cohort.split(",")
    } 
    try {
      const result = await fetchUsersOfOrganizationWithPagination(
        organization,
        1,
        cohort,
        true,
        "",
        3,
        "pointsThisWeek"
      );

      const filteredUsers = result.users.filter((user: PlayerInfo) => user.points_this_week > 0); // Specify user type
      setTopUsers(filteredUsers);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching top users:", error);
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchTopUsers(subdomain);
  }, [subdomain]);

  return (
    <div className="topScoresCollection" data-testid="topScoresCollection">
      <div className="topScoresCollection__header">
        <h1 data-testid="topScoresHeader">This Week's Top Scores</h1>
      </div>
      <div className="topScoresCollection__container" data-testid="topScoresContainer">
        {!topUsers.length && loading && <LoadingCards count={3} />}
        {topUsers.length > 0 && !loading && (
          <>
            {topUsers.map((userInfo, index) => {
              return (
                <Link
                  key={userInfo.name + index}
                  to={{
                    pathname: "/fellow/" + userInfo.uuid,
                    state: {
                      playerInfo: userInfo,
                    },
                  }}
                  data-testid={`playerCard-${index}`} 
                >
                  <PlayerCard playerInfo={userInfo} place={index} topScore />
                </Link>
              );
            })}
          </>
        )}
      </div>
      {topUsers.length < 1 && !loading && <EmptyView styles={emptyViewStyles} text="Be the First to Score This Week!" />}
    </div>
  );
}

export default TopScoresCollection;

