// AccountSettings.tsx
import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { useHistory } from "react-router-dom";

// MUI
import LoadingButton from "@mui/lab/LoadingButton";
import {
  TextField,
  Grid,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

// Layout
import ShadowBox from "../../layoutComponents/shadowBox/ShadowBox";

// Components
import NameInput from "../../components/inputs/NameInput";
import EmailInput from "../../components/inputs/EmailInput";
import DeleteAccount from "../../features/deleteAccount/DeleteAccount";
import LeetCodeIntegration from "./LeetCodeIntegration";

// API & Utilities
import { fetchUserProfile, updateUserProfile, UserProfileData } from "../../services/usersService";
import { useToast } from "../../hooks/useToast";

// Styles
import "./AccountSettings.scss";

interface AccountSettingsProps {
  orgConfig: {
    classes?: string[];
    [key: string]: any;
  };
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormDataType {
  name: string;
  email: string;
  cohort: string;
  codewars: string;
  github: string;
}

const AccountSettings: React.FC<AccountSettingsProps> = ({ orgConfig, setLoggedIn }) => {
  const history = useHistory();
  const { showToast, toastMessage, displayToast, hideToast } = useToast();

  // State management
  const [playerInfo, setPlayerInfo] = useState<UserProfileData | null>(null);
  const [formData, setFormData] = useState<FormDataType>({
    name: "",
    email: "",
    cohort: "",
    codewars: "",
    github: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [errorsArr, setErrorsArr] = useState<string[]>([]);

  // Destructure form data for easier access
  const { name, email, cohort, codewars, github } = formData;

  // Fetch user profile data
  useEffect(() => {
    const fetchProfile = async (): Promise<void> => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
          history.push("/login");
          return;
        }

        const data = await fetchUserProfile(accessToken);
        setPlayerInfo(data);
        setFormData({
          name: data.name || "",
          email: data.email || "",
          cohort: data.cohort || "",
          codewars: data.username || "",
          github: data.github_username || "",
        });
      } catch (err) {
        console.error("Error fetching profile:", err);
        history.push("/login");
      }
    };

    fetchProfile();
  }, [history]);

  // Handle form input changes
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent): void => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setLoading(true);

    const trimmedData = {
      name: name.trim(),
      email: email.trim(),
      codewars: codewars?.trim(),
      cohort,
      github: github?.trim(),
    };

    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken || !playerInfo?.uuid) {
        throw new Error("Authentication error");
      }
      
      const updatedUser = await updateUserProfile(
        playerInfo.uuid,
        trimmedData,
        accessToken
      );

      setPlayerInfo(updatedUser);
      displayToast("Your account information has been updated.");
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : "Failed to update user";
      displayToast(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="accountSettings">
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={hideToast}
        message={toastMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />

      <ShadowBox title="Account Settings">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <NameInput
                value={name}
                handleChange={handleChange}
                errorsArr={errorsArr}
                setErrorsArr={setErrorsArr}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <EmailInput
                handleChange={handleChange}
                errorsArr={errorsArr}
                setErrorsArr={setErrorsArr}
                orgConfig={orgConfig}
                value={email}
              />
            </Grid>
            {orgConfig.classes && (
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Your Class</InputLabel>
                  <Select
                    variant="outlined"
                    required
                    name="cohort"
                    value={cohort}
                    label="Your Class"
                    id="cohort"
                    onChange={handleChange}
                  >
                    {orgConfig.classes.map((item: string, index: number) => (
                      <MenuItem key={`${item}-${index}`} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item sm={6} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="codewars"
                label="Codewars username"
                name="codewars"
                value={codewars}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="github"
                label="Github username"
                name="github"
                value={github}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <div className="acctInfo__submit">
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={loading}
            >
              Update
            </LoadingButton>
          </div>
        </form>
      </ShadowBox>

      {playerInfo && (
        <LeetCodeIntegration
          userId={playerInfo.uuid}
          currentLeetcodeId={playerInfo.leetCodeUsername}
          displayToast={displayToast}
        />
      )}

      {playerInfo?.role === "admin" && (
        <ShadowBox title="Problem Management">
          <a href="/problems">
            <LoadingButton variant="contained" color="primary">
              Go to Problems Page
            </LoadingButton>
          </a>
        </ShadowBox>
      )}

      {playerInfo && (
        <DeleteAccount 
          userId={playerInfo.uuid} 
          setLoggedIn={setLoggedIn}
          displayToast={displayToast}
        />
      )}
    </div>
  );
};

export default AccountSettings;